.mention-container {
  position: relative;
  width: 100%;
  margin: auto;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.dropdown {
  position: absolute;
  top: 60px; /* Adjust according to your textarea's height */
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
