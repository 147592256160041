@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
 
@font-face {
    font-family: 'Satoshi'; 
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}


.zambia_logo_text {
    width: 15rem;
    color: #2E7D32;
    font-family: "Open Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    /* 153.846% */
}

.zambia_login_text {
    color: #4B5563;
    text-align: center;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
}

.login-form-item label {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.remember_text {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.or_text {
    color: #4B5563;
    text-align: center;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.forgot_text { 
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
}

.dnt_have_account_text {
    color: #4B5563;
    text-align: center;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
}

.heading_1 {
    color: var(--Text-primary, #212121);
    text-align: center;
    font-family: "Satoshi";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.7rem */
}

.heading_4 {
    color: #FFF;
    font-family: "Satoshi";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
}
 

.heading_5 {
    color: var(--text-black, #222);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.5rem */
}

.paragraph_1 {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.label_1 {
    color: var(--Text-primary, #212121);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.label_1_profile {
    color: var(--Text-primary, #212121);
    font-family: "Lexend", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.label_2 {
    color: var(--Text-tertiary, #9E9E9E);
    font-family: "Lexend", sans-serif;
    font-size: 16px; 
    font-weight: 500;
    line-height: 24px;
}

.Label_3{
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
 
}
.label_4 {
    color: #6B7280; 
    font-family: "Satoshi";
    font-size: 16px; 
    font-weight: 700;
    line-height: 24px; 
}

.label_agency_title {
    color: #475467; 
    font-family: "Satoshi";
    font-size: 24px; 
    font-weight: 700;
    line-height: 32px; 
}

.card-border{
    border: 1px solid #ECECEC;
}

.typography_h1 {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 30px; 
    font-weight: 600;
    line-height: 36px;
}

.typography_sub1 {
    color: #374151;
    font-family: "Lexend", sans-serif;
    font-size: 18px; 
    font-weight: 500;
    line-height: 28px;
}

.typography_sub2 {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 14px; 
    font-weight: 500;
    line-height: 20px;
}

.typography_or { 
    font-family: "Lexend", sans-serif;
    font-size: 14px; 
    font-weight: 500;
    line-height: 20px;
}
.typography_p { 
    font-family: "Lexend", sans-serif;
    font-size: 18px; 
    font-weight: 400;
    line-height: 28px;
    color: #4B5563;
}
.typography_p_2 { 
    font-family: "Lexend", sans-serif;
    font-size: 14px; 
    font-weight: 400;
    line-height: 20px;
    color: #4B5563;
}

.typography_p_3 { 
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
}

 
 
.account-card { 
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #0000000D; 
    box-shadow: 0px 0px 0px 1px #D1D5DB; 
}

.link2 {
    color: #0D69FF; 
    font-family: "Lexend", sans-serif;
    font-size: 16px; 
    font-weight: 500;
    line-height: 24px; 
}
 

.lexend_text{
    font-family: "Lexend", sans-serif;
}
 
.product_title {
    color: #222222; 
    font-family: "Roboto", sans-serif;
    font-size: 20px; 
    font-weight: 300; 
}
.product_sub {
    color: #222222; 
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px; 
    font-weight: 400; 
}
 

.product-card{
    border: 1px #cccccc solid;
    transition: border-top-color 0.3s;
}
.product-card:hover{ 
    border-top: 5px solid #2C963F;
}
.product_info_title{
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.product_request_title {
    color: #222222; 
    font-family: "Roboto", sans-serif;
    font-size: 24px; 
    font-weight: 400; 
}
.reseller_card_title{
    color: #222222; 
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem; 
    font-weight: 500; 
}
.reseller_card_title:hover{
    color: var(--darkGreen);  
}

.reseller_card_sub_title{
    color: #222222; 
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px; 
    font-weight: 400; 
}
.prod_view_title{
    color: #222222; 
    font-family: "Source Sans 3", sans-serif;
    font-size: 31px; 
    font-weight: 400; 
}
.prod_view_subtitle{
    color: #222222; 
    font-family: "Roboto", sans-serif;
    font-size: 25px; 
    font-weight: 300; 
}

.prod_view_table{
    color: #222222; 
    font-family: "Source Sans 3", sans-serif;
    font-size: 18px; 
    font-weight: 400; 
}

.page-navigation_title {
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    color: #222222; 
    font-weight: 300; 
}
.paragraph {
    color: #222222; 
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px; 
    font-weight: 400; 
}
.payment-methods {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #c8c7cc; 
    border-radius: .2rem; 
}
.payment-method {  
    font-size: 1.2rem;
    font-family: "Source Sans 3", sans-serif;
    color: #222222; 
}
.permission_title{
    color: #222222; 
    font-family: "Roboto", sans-serif;
    font-size: 18px; 
    font-weight: 500; 
}
.dash-card{
    border-radius: 1.25rem;
    border: 1px solid var(--Stroke-Color, #EFF0F6);
    background: var(--White, #FFF);
   
    height: 9.75294rem; 
}
.dash-card-title{
    color: var(--Base-Shade-70, rgba(0, 0, 0, 0.70));
    font-family: "Inter", sans-serif;
    font-size: .9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.00963rem;
}
.dash-card-total{
    color: var(--Base-Shade-100, #000); 
    font-family: "Inter", sans-serif;
    font-size: .9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.graph-card{
    border-radius: 1.25rem;
    border: 1px solid var(--Stroke-Color, #EFF0F6);
    background: var(--White, #FFF); 
}

.grap-title{
    color: var(--Gray-1, #333);
    font-family: "DM Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3125rem;  
    text-transform: capitalize;
}

.grap-sub-title{
    color: var(--black-100, #1C1C1C); 
    font-family: "DM Sans";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; 
}

.profile_header {
    color: var(--Label-label, #6B7280); 
    font-family: Lexend;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.35rem */
}

.active {
    color: var(--Link-primary, #0047BA); 
    font-family: "Lexend";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.35rem */
}
.name_summary{
    font-family: "Lexend", sans-serif;
    font-size: 45px; 
    font-weight: 500;
    line-height: 54px;
    color:#0C0C0C;
}
.typography_profile{
    font-family: "Lexend", sans-serif;
    font-size: 18px; 
    font-weight: 500;
    line-height: 21.6px;
    color: #0C0C0C;
}

.reg_number_text {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-radius: 0.1875rem;
    background: rgba(87, 168, 99, 0.25);
    color: #212121;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.billing-card{
    border: 1px #cccccc solid;
    transition: border-top-color 0.3s;
}
 
.empty-desc{
	color: var(--Text-secondary, #616161); 
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;  
}

.report-card{
    border: 1px #cccccc solid; 
    border-radius: 10px;
}