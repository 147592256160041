/* styles.css */

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .animated-image {
    animation: moveUpDown 3s infinite alternate;
  }
  
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  